import axios from 'axios';
import * as Sentry from '@sentry/vue';

const transformRequest = (jsonData = {}) => Object.entries(jsonData)
  .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
  .join('&');

export default {
  namespaced: true,
  state: {
    dataAPIBase: process.env.VUE_APP_OCAPI_DATA_URL,
    shopAPIBase: process.env.VUE_APP_OCAPI_SHOP_URL,
    dataAuthUrl: process.env.VUE_APP_OCAPI_DATA_AUTH_URL,
    shopClientId: process.env.VUE_APP_OCAPI_SHOP_CLIENT_ID,
    dataClientId: process.env.VUE_APP_OCAPI_DATA_CLIENT_ID,
    dataClientSecret: process.env.VUE_APP_OCAPI_DATA_CLIENT_SECRET,
    siteId: process.env.VUE_APP_OCAPI_SITE_ID,
    libraryId: process.env.VUE_APP_LIBRARY_ID,
    customerListId: process.env.VUE_APP_OCAPI_CUSTOMER_LIST_ID || process.env.VUE_APP_OCAPI_SITE_ID,
    customer: null,
    customerAuth: null,
    customerExtProfile: null,
    customerGroup: null,
    dataAuthToken: null,
    dataAuthTokenExpiration: null,
    login: null,
    lastLogin: null,
    headerBannerSlotConfiguration: null,
    populationId: null,
  },
  mutations: {
    setDataAuthToken(state, payload) {
      state.dataAuthToken = payload;
    },
    setDataAuthTokenExpiration(state, payload) {
      state.dataAuthTokenExpiration = payload;
    },
    setCustomerAuth(state, payload) {
      state.customerAuth = payload;
    },
    setCustomer(state, payload) {
      state.customer = payload;
    },
    setCustomerExtProfile(state, payload) {
      state.customerExtProfile = payload;
    },
    setCustomerGroup(state, payload) {
      state.customerGroup = payload;
    },
    setCurrentUserLogin(state, payload) {
      state.login = payload;
    },
    setLastLogin(state, payload) {
      state.lastLogin = payload;
    },
    setHeaderBannerSlotConfiguration(state, payload) {
      state.headerBannerSlotConfiguration = payload;
    },
    setPopulationId(state, payload) {
      state.populationId = payload;
    },
  },
  actions: {
    init({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuth')
          .then(resolve)
          .catch(reject);
      });
    },
    // auth
    fetchAuthToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        const today = new Date();
        if (state.dataAuthToken !== null && today.getTime() < state.dataAuthTokenExpiration.getTime()) {
          // return current token if not expired
          resolve(state.dataAuthToken);
        } else {
          const auth = btoa(`${state.dataClientId}:${state.dataClientSecret}`);
          // request a new token
          axios({
            method: 'post',
            url: '/dwsso/oauth2/access_token',
            baseURL: state.dataAuthUrl,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Basic ${auth}`,
            },
            transformRequest: jsonData => transformRequest(jsonData),
            data: {
              grant_type: 'client_credentials',
            },
          })
            .then((response) => {
              const expires = today.getTime() + response.data.expires_in * 1000;
              commit('setDataAuthToken', response.data.access_token);
              commit('setDataAuthTokenExpiration', new Date(expires));
              resolve(state.dataAuthToken);
            })
            .catch(reject);
        }
      });
    },
    fetchUserAuthWithToken({ commit, dispatch, getters, state }) {
      return new Promise((resolve, reject) => {
        if (getters.parsedCustomerAuthToken) {
          const now = new Date();
          const tokenExpiration = new Date(getters.parsedCustomerAuthToken.exp * 1000);
          if (now.getTime() < tokenExpiration.getTime()) {
            return resolve();
          }
        }
        dispatch('fetchAuthToken')
          .then(() => {
            axios({
              method: 'post',
              url: '/customers/auth/trustedsystem',
              baseURL: getters.shopApiUrl,
              headers: {
                Authorization: `Bearer ${state.dataAuthToken}`
              },
              data: {
                client_id: state.shopClientId,
                login: state.login
              }
            })
            .then(response => {
              commit('setCustomer', response.data);
              commit('setCustomerAuth', response.headers.authorization);
              resolve(response);
            })
            .catch(reject)
          })
      });
    },
    fetchUserAuth({ commit, state, getters }) {
      return new Promise((resolve, reject) => {
        const username = state.shopClientId;
        const password = state.dataClientSecret;
        const auth = btoa(`${username}:${password}`);
        // request a new token
        axios({
          method: 'post',
          url: '/customers/auth',
          baseURL: getters.shopApiUrl,
          headers: {
            Authorization: `Basic ${auth}`,
          },
          params: {
            client_id: state.shopClientId,
          },
          data: {
            type: 'credentials',
          },
        })
          .then((response) => {
            commit('setCustomer', response.data);
            commit('setCustomerAuth', response.headers.authorization);
            resolve(state.dataAuthToken);
          })
          .catch(reject);
      });
    },
    fetchGuestAuth({ commit, state, getters }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/customers/auth',
          baseURL: getters.shopApiUrl,
          params: {
            client_id: state.shopClientId,
          },
          data: {
            type: 'guest',
          }
        })
          .then((response) => {
            commit('setCustomer', response.data);
            commit('setCustomerAuth', response.headers.authorization);
            resolve(response);
          })
          .catch(reject);
      });
    },
    searchCustomers({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => axios({
            method: 'post',
            url: `/customer_lists/${state.customerListId}/customer_search`,
            baseURL: getters.dataApiUrl,
            headers: {
              Authorization: `Bearer ${state.dataAuthToken}`
            },
            data: {
              ...payload
            }
          }))
          .then(resolve)
          .catch(reject);
      })
    },

    // customer
    createCustomerExtProfile({ commit, state, getters, rootGetters }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/customers/ext_profile',
          baseURL: getters.shopApiUrl,
          headers: {
            Authorization: state.customerAuth
          },
          data: {
            authentication_provider_id: 'PingOne',
            external_id: rootGetters['ping/parsedIdToken'].preferred_username,
            email: rootGetters['ping/parsedIdToken'].email
          }
        })
          .then((response) => {
            commit('setCustomerExtProfile', response.data)
            resolve(response);
          })
          .catch(reject);
      });
    },
    fetchCustomer({ commit, state, getters }, payload) {
      const customer_id = payload || state.customer.customer_id;
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/customers/${customer_id}`,
          baseURL: getters.shopApiUrl,
          headers: {
            Authorization: state.customerAuth,
          },
        })
          .then(response => {
            commit('setCustomer', response.data)
            resolve(response);
          })
          .catch(reject);
      });
    },
    updateCustomer({ state, commit, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
        .then(() => {
            axios({
              method: 'patch',
              url: `/customers/${state.customer.customer_id}`,
              baseURL: getters.shopApiUrl,
              headers: {
                Authorization: state.customerAuth,
              },
              data: {
                ...payload
              }
            })
            .then(response => {
              commit('setCustomer', response.data)
              resolve(response);
            })
            .catch(reject);
        })
      });
    },
    fetchCustomerBaskets({ state, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'get',
            url: `/customers/${state.customer.customer_id}/baskets`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
          }))
          .then((response) => resolve(response.data.baskets || []))
          .catch(reject);
      });
    },

    fetchContentSlotConfiguration({ state, getters, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => axios({
            method: 'get',
            url: `/sites/${state.siteId}/slots/${payload.slot}/slot_configurations/${payload.slot_configuration}`,
            baseURL: getters.dataApiUrl,
            headers: {
              Authorization: `Bearer ${state.dataAuthToken}`
            },
            // params: {
            //   select: '(**,member_count)'
            // }
          }))
          .then(response => {
            commit('setHeaderBannerSlotConfiguration', response.data);
            resolve(response);
          })
          .catch(reject);
      });
    },

    fetchHeaderBannerSlotConfiguration({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchContentSlotConfiguration', {
          slot: 'header-banner-m',
          slot_configuration: 'CustomerCare'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          })
      })
    },

    // customer groups
    fetchCustomerGroup({ state, getters, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => axios({
            method: 'get',
            url: `/sites/${state.siteId}/customer_groups/${payload}`,
            baseURL: getters.dataApiUrl,
            headers: {
              Authorization: `Bearer ${state.dataAuthToken}`
            },
            params: {
              select: '(**,member_count)'
            }
          }))
          .then(response => {
            commit('setCustomerGroup', response.data);
            resolve(response);
          })
          .catch((error) => {
            Sentry.captureMessage("Fetch Customer Group Error")
            Sentry.captureException(error)
            reject(error)
          });
      });
    },

    // find customer group Population
    findCustomerGroupPopulation({ state, getters, commit, dispatch }, payload) {
      const newPayload = {
        query :
        {
          bool_query: {
            must: [
              {
                term_query: {
                  fields: ["c_hbcConnectIDPID"],
                  operator: "is",
                  values: [payload]
                }
              }
            ]
          }
        },
        select: "(count,hits[].(id))"
     }
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => axios({
            method: 'post',
            url: `/sites/${state.siteId}/customer_group_search`,
            baseURL: getters.dataApiUrl,
            headers: {
              Authorization: `Bearer ${state.dataAuthToken}`
            },
            data: newPayload
          }))
          .then(response => {
            commit('setPopulationId', response.data?.hits[0].id);
            resolve(response);
          })
          .catch(reject);
      });
    },

    // basket
    createBasket({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'post',
            url: '/baskets',
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            params: {
              client_id: state.shopClientId,
            },
            data: {
              ...payload
            }
          }))
          .then(resolve)
          .catch(reject);
      });
    },
    addCustomerInfoToBasket({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'put',
            url: `/baskets/${payload.basket_id}/customer`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            params: {
              client_id: state.shopClientId,
            },
            data: payload.data
          }))
          .then(resolve)
          .catch(reject);
      });
    },
    fetchBasket({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'get',
            url: `/baskets/${payload}`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
          }))
          .then(resolve)
          .catch(reject);
      });
    },

    deleteBasket({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'delete',
            url: `/baskets/${payload}`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
          }))
          .then(resolve)
          .catch(reject);
      });
    },
    setBasketPaymentInstrument({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'post',
            url: `/baskets/${payload.basket_id}/payment_instruments`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: payload.data,
          }))
          .then(resolve)
          .catch(reject);
      })
    },
    setBasketBillingAddress({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'put',
            url: `/baskets/${payload.basket_id}/billing_address`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: payload.data,
          }))
          .then(resolve)
          .catch(reject);
      })
    },
    setShipmentShippingAddress({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'put',
            url: `/baskets/${payload.basket_id}/shipments/${payload.shipment_id}/shipping_address`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: payload.data
          }))
          .then(resolve)
          .catch(reject);
      })
    },
    setShipmentShippingMethod({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'put',
            url: `/baskets/${payload.basket_id}/shipments/${payload.shipment_id}/shipping_method`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: payload.data,
          }))
          .then(resolve)
          .catch(reject);
      })
    },
    updateShipment({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'patch',
            url: `/baskets/${payload.basket_id}/shipments/${payload.shipment_id}`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: payload.data,
          }))
          .then(resolve)
          .catch(reject);
      })
    },
    addItemToBasket({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'post',
            url: `/baskets/${payload.basket_id}/items`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            params: {
              client_id: state.shopClientId,
            },
            data: [
              payload.item
            ],
          }))
          .then(resolve)
          .catch(reject);
      });
    },
    updateItemInBasket({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'patch',
            url: `/baskets/${payload.basket_id}/items/${payload.item_id}`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: {
              ...payload.data
            },
          }))
          .then(resolve)
          .catch(reject)
      })
    },
    createOrder({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'post',
            url: `/orders`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: {
              ...payload
            },
          }))
          .then(resolve)
          .catch(reject);
      })
    },
    patchOrder({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'patch',
            url: `/orders/${payload.order_no}`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: {
              ...payload.data
            },
          }))
          .then(resolve)
          .catch((error) => {
            Sentry.captureMessage("Disptach Fail fetch User Auth With Token")
            Sentry.captureException(error)
            reject(error)
          });
      })
    },
    updateOrderStatus({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => axios({
            method: 'put',
            url: `/sites/${state.siteId}/orders/${payload.order_no}/${payload.status_name}`,
            baseURL: getters.dataApiUrl,
            headers: {
              Authorization: `Bearer ${state.dataAuthToken}`,
            },
            data: {
              ...payload.data
            },
          }))
          .then(resolve)
          .catch(reject);
      })
    },

    // categories
    fetchCategories({ state, getters }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/categories/${payload.category_id}`,
          baseURL: getters.shopApiUrl,
          params: {
            client_id: state.shopClientId,
            ...payload.params
          },
        })
        .then(resolve)
        .catch(reject);
      });
    },

    // products
    fetchProducts({ state, getters }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/product_search',
          baseURL: getters.shopApiUrl,
          params: {
            refine: `cgid=${payload.cgid}`,
            count: 200,
            start: payload?.start || 0,
            expand: 'images,availability',
            client_id: state.shopClientId,
          },
        })
          .then(resolve)
          .catch(reject);
      });
    },

    fetchAllProducts({ dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchProducts', { cgid: rootState.front.defaultCategory })
          .then(response => {
            const remainingCalls = [];
            let hits = response.data.hits;
            if (response.data.total > response.data.count) {
              for (let i = response.data.count; i < response.data.total; i += response.data.count) {
                remainingCalls.push(dispatch('fetchProducts', { cgid: rootState.front.defaultCategory, start: i }));
              }
              Promise.all(remainingCalls)
                .then(responses => {
                  responses.forEach(response => {
                    hits = hits.concat(response.data.hits);
                  });
                  resolve(hits);
                })
                .catch(reject);
            } else {
              resolve(hits);
            }
          });
      })
    },

    fetchMultipleProducts({ state, getters }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/products/(${payload.product_ids.join(',')})`,
          baseURL: getters.shopApiUrl,
          params: {
            client_id: state.shopClientId,
          }
        })
          .then(resolve)
          .catch(reject);
      });
    },

    fetchProduct({ state, getters }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/products/${payload.product_id}`,
          baseURL: getters.shopApiUrl,
          params: {
            expand: 'images',
            client_id: state.shopClientId,
          },
        })
        .then(resolve)
        .catch(reject);
      });
    },
    fetchLibraryFolder({ state, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
        .then(() => {
          return axios({
            method: 'get',
            url: `/libraries/${state.libraryId}/folders/${payload.folder_id}/content`,
            baseURL: getters.dataApiUrl,
            params: {
              client_id: state.dataClientId,
            },
            headers: {
              Authorization: `Bearer ${state.dataAuthToken}`,
            },
          });
        })
        .then(resolve)
        .catch(reject);
      });
    },
    // orders
    fetchOrders({ state, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => {
            return axios({
              method: 'post',
              url: '/order_search',
              baseURL: getters.shopApiUrl,
              params: {
                client_id: state.shopClientId,
              },
              headers: {
                Authorization: `Bearer ${state.dataAuthToken}`,
              },
              data: payload
            })
          })
        .then(resolve)
        .catch(reject);
      });
    },

    fetchCustomObjects({ state, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => {
            return axios({
              method: 'post',
              url: `/custom_objects_search/${payload.object_type}`,
              baseURL: getters.dataApiUrl,
              headers: {
                Authorization: `Bearer ${state.dataAuthToken}`,
              },
              data: {
                ...payload.data
              }
            })
          })
          .then(resolve)
          .catch(reject)
      });
    }
  },
  getters: {
    shopApiUrl(state) {
      return `${state.shopAPIBase}`;
    },
    dataApiUrl(state) {
      return `${state.dataAPIBase}`;
    },
    headerContentSlot(state) {
      const callout = state.headerBannerSlotConfiguration?.callout_msg?.default?.markup || null;
      const body = state.headerBannerSlotConfiguration?.slot_content?.body?.default?.markup || null;
      return (state.headerBannerSlotConfiguration?.customer_groups?.includes(state.customerGroup.id) && callout && body)
        ? { callout, body }
        : null;
    },
    parsedCustomerAuthToken(state) {
      if (state.customerAuth) {
        const token = state.customerAuth.split('Bearer ')[1];
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      }
    }
  }
};
